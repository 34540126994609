<template>
  <div>
    <h1>{{ title }}</h1>
    <div v-if="!accessToken">
      <div class="intro">
        <p>Are you ready to push the boundaries of your musical knowledge and become a legend in the music community?
          SuperScrobbler is the ultimate Last.fm tool that helps you explore all 6,000 genres in the world-famous <a
              href="https://open.spotify.com/playlist/69fEt9DN5r4JQATi52sRtq?si=e5abbf09b1424e4a">"The Sound of
            Everything" playlist</a> from <a href="https://everynoise.com">everynoise.com</a></p>
        <p>Discover new artists and genres, track your progress, and become part of a passionate community of music
          enthusiasts who share your goal of becoming a true SuperScrobbler. The SuperScrobbler challenge is not just
          about music – it's about pushing yourself to new limits and gaining a sense of accomplishment as you achieve
          this ultimate feat.</p>
        <p>Join the SuperScrobbler community today and unlock a world of musical exploration. Become a part of a
          legendary group of music enthusiasts who have taken on the ultimate challenge – to scrobble every genre in the
          world. Let SuperScrobbler be your guide on this journey and experience music like never before.</p>
      </div>

      <button class="login" @click="loginWithSpotify">Login with Spotify</button>
    </div>
    <div v-else>
      <div v-if="started">
        <div class="progress-bar">
          <div class="label">Fetching Spotify playlist:</div>
          <div class="bar" :style="{ width: progressBarWidth, background: progressBarBackground }">{{
              progressBarWidth
            }}
          </div>
        </div>

        <div v-if="loadedItems >= totalItems">
          <div class="progress-bar">
            <div class="label">Fetching your scrobbles from last.fm:</div>
            <div class="bar" :style="{ width: lastfmProgressBarWidth, background: lastfmProgressBarBackground }">
              {{ lastfmProgressBarWidth }}
            </div>
          </div>


          <div v-if="lastfmLoadedItems >= totalItems">
            <div class="stats">
              <div>Total artists scrobbled: {{ totalArtistsScrobbled }} / {{ totalItems }} (<span
                  :style="{color: percentColor}">{{ (totalArtistsScrobbled / totalItems * 100).toFixed(2) }}%</span>)
              </div>
              <div>
                {{ getProgressText }}
              </div>
              <div>Total scrobbles: {{ totalScrobbles }}</div>
            </div>
            <table class="results">
              <thead>
              <tr>
                <th>Artist</th>
                <th>Scrobbles</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="artist in artists" :key="artist.id">
                <td>{{ artist.name }}</td>
                <td>{{ artist.scrobbles }}</td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>


      </div>
      <div v-else>

        <div style="display: inline-grid;">
          <label for="lastfm-username">Last.fm user name:</label>
          <input id="lastfm-username" v-model="lastfmUsername" type="text" @keyup.enter="fetchData"/>
          <button class="go" @click="fetchData">Go!</button>
        </div>
        <div class="intro">
          <p>Are you ready to push the boundaries of your musical knowledge and become a legend in the music community?
            SuperScrobbler is the ultimate Last.fm tool that helps you explore all 6,000 genres in the world-famous <a
                href="https://open.spotify.com/playlist/69fEt9DN5r4JQATi52sRtq?si=e5abbf09b1424e4a">"The Sound of
              Everything" playlist</a> from <a href="https://everynoise.com">everynoise.com</a></p>
          <p>Discover new artists and genres, track your progress, and become part of a passionate community of music
            enthusiasts who share your goal of becoming a true SuperScrobbler. The SuperScrobbler challenge is not just
            about music – it's about pushing yourself to new limits and gaining a sense of accomplishment as you achieve
            this ultimate feat.</p>
          <p>Join the SuperScrobbler community today and unlock a world of musical exploration. Become a part of a
            legendary group of music enthusiasts who have taken on the ultimate challenge – to scrobble every genre in the
            world. Let SuperScrobbler be your guide on this journey and experience music like never before.</p>
        </div>

      </div>


    </div>
  </div>
</template>

<script>
import axios from 'axios';

const LASTFM_API_KEY = '50e71df61065f5f8b5b6f75d2922c595';

// Import the fetchAllArtists function
async function fetchAllArtists(username, page = 1, limit = 1000, pageCallback = null) {
  const artists = [];
  let currentPage = page;
  let totalPages = 0;

  while ((currentPage - 1) <= totalPages) {
    const url = `https://ws.audioscrobbler.com/2.0/?method=user.gettopartists&user=${username}&api_key=${LASTFM_API_KEY}&format=json&limit=${limit}&page=${currentPage}`;
    const response = await fetch(url);
    const data = await response.json();
    totalPages = parseInt(data.topartists["@attr"].totalPages);

    for (let i = 0; i < data.topartists.artist.length; i++) {
      const artist = data.topartists.artist[i];
      artists.push([artist.name, parseInt(artist.playcount)]);
    }

    if (pageCallback) {
      pageCallback(currentPage, totalPages);
    }

    currentPage++;
  }

  return artists;
}

const progressTexts = {
  "0": "You've just started your journey to becoming a superscrobbler! Don't worry if your scrobbles are low right now, every journey starts with a single step. Keep it up!",
  "1": "You're on your way! Keep it up!",
  "5": "You're making great progress!",
  "10": "You're doing awesome! Keep it up!",
  "15": "You're making fantastic progress!",
  "20": "You're on fire! Keep it up!",
  "25": "You're unstoppable! Keep going!",
  "30": "You're a superscrobbler in the making!",
  "35": "You're making history! Keep it up!",
  "40": "You're on your way to becoming a legendary superscrobbler!",
  "45": "You're unstoppable! Nothing can stop you now!",
  "50": "You're halfway there! Keep going strong!",
  "55": "You're making incredible progress! Keep it up!",
  "60": "You're on your way to the top! Keep going!",
  "65": "You're a true superscrobbler! Keep it up!",
  "70": "You're scrobbling like a boss! Keep going!",
  "75": "You're making history! Keep it up!",
  "80": "You're a superscrobbler hero! Keep going strong!",
  "85": "You're a scrobbling machine! Nothing can stop you!",
  "90": "You're scrobbling like a pro! Keep it up!",
  "95": "You're a true superscrobbler! You're unstoppable!",
  "100": "You did it! You're a legendary superscrobbler!"
};


export default {
  name: 'App',
  data() {
    return {
      title: 'SuperScrobbler',
      accessToken: localStorage.getItem('access_token'),
      artists: [], // Your artist list
      started: false,
      lastfmUsername: null,
      loadedItems: 0, // Counter for loaded items
      lastfmLoadedItems: 0,
      lastfmProgressBarWidth: 0,
      totalArtistsScrobbled: 0,
      totalItems: 0,
      totalScrobbles: 0,
      loading: false,
    };
  },
  computed: {
    progressBarWidth() {
      return `${((this.loadedItems / this.totalItems) * 100).toFixed(2)}%`;
    },
    progressBarBackground() {
      const percentage = this.loadedItems / this.totalItems;
      const hue = percentage * 360;
      return `linear-gradient(to left, hsl(${hue}, 100%, 50%), hsl(${hue + 120}, 100%, 50%), hsl(${hue + 240}, 100%, 50%))`;
    },
    lastfmProgressBarBackground() {
      const percentage = this.lastfmLoadedItems / this.totalItems;
      const hue = percentage * 360;
      return `linear-gradient(to right, hsl(${hue}, 100%, 50%), hsl(${hue + 120}, 100%, 50%), hsl(${hue + 240}, 100%, 50%))`;
    },
    percentColor() {
      const percentage = (this.totalArtistsScrobbled / this.totalItems * 100).toFixed(2)

      function getColorFromPercentage(percentage) {
        const red = percentage < 50 ? 255 : Math.floor(255 - (percentage * 2 - 100) * 255 / 100);
        const green = percentage > 50 ? 255 : Math.floor((percentage * 2) * 255 / 100);
        return `rgb(${red}, ${green}, 0)`;
      }

      return getColorFromPercentage(percentage);
    },
    getProgressText() {
      const percentage = (this.totalArtistsScrobbled / this.totalItems * 100).toFixed(2)
      const keys = Object.keys(progressTexts).map(Number).sort((a, b) => a - b);
      const index = keys.findIndex((key, i, arr) => key <= percentage && (i === arr.length - 1 || arr[i + 1] > percentage));
      return progressTexts[keys[index]];
    }

  },
  methods: {
    async fetchData() {
      if (this.lastfmUsername) {
        this.loading = true;
        this.started = true;
        this.artists = await this.getPlaylistArtists(this.accessToken, this.lastfmUsername);
        this.loading = false;
      } else {
        alert('Please enter a Last.fm username.');
      }
    },
    async getPlaylistLength(accessToken) {
      const playlistId = '69fEt9DN5r4JQATi52sRtq';
      const url = `https://api.spotify.com/v1/playlists/${playlistId}`;
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      let response = null;
      try {
        response = await axios.get(url, config);
      } catch (error) {
        if (error.response.status === 401) {
          await this.refreshToken();
          // Call the API again with the new access token
        } else {
          console.error(`Error message: ${error.response.data}`);
          return [];
        }
      }

      return response.data.tracks.total;
    },
    async loginWithSpotify() {
      const client_id = 'e8b4bc5575774ffa92a220fcd17bee8e';
      const redirect_uri = 'https://superscrobbler.com/callback';
      const scopes = 'user-read-private user-read-email playlist-read-private';
      const url = `https://accounts.spotify.com/authorize?response_type=token&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${encodeURIComponent(scopes)}`;
      window.location.href = url;
    },
    async getPlaylistArtists(accessToken) {
      const playlistId = '69fEt9DN5r4JQATi52sRtq';
      const url = `https://api.spotify.com/v1/playlists/${playlistId}/tracks`;
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          fields: 'items(track(name,artists(name))),next',
          limit: 100,
        },
      };
      const allArtists = {};
      let nextUrl = url;
      while (nextUrl) {
        const response = await axios.get(nextUrl, config);
        const {items, next} = response.data;
        // const {items} = response.data;

        for (const item of items) {
          const {artists} = item.track;
          this.loadedItems++;
          for (const artist of artists) {
            if (artist) {
              const artistName = artist.name;
              if (!allArtists[artistName]) {
                allArtists[artistName] = {
                  id: artist.id,
                  name: artistName,
                  scrobbles: 0,
                };
              }

            }
          }
        }
        nextUrl = next;
      }

      var artists1 = Object.values(allArtists);

      // Fetch all Last.fm artists for the user in a single request
      const lastFmArtists = await fetchAllArtists(this.lastfmUsername, 1, 1000, (page, totalPages) => {
        this.lastfmProgressBarWidth = `${Math.min(((page / totalPages) * 100).toFixed(2), 100)}%`;
      });

      // Create a map of the Last.fm artists for easy lookup
      const lastFmArtistMap = new Map();
      for (const [name, scrobbles] of lastFmArtists) {
        lastFmArtistMap.set(name.toLowerCase(), scrobbles);
      }

      // Add scrobbles to the Spotify artists and calculate the total scrobbles
      for (const artist of artists1) {
        const scrobbles = lastFmArtistMap.get(artist.name.toLowerCase()) || 0;
        this.totalScrobbles += scrobbles;
        this.lastfmLoadedItems++;
        artist.scrobbles = parseInt(scrobbles);
      }

      // Sort the list by scrobble count and return it
      artists1.sort((a, b) => b.scrobbles - a.scrobbles);
      this.totalArtistsScrobbled = artists1.filter(artist => artist.scrobbles > 0).length;

      return artists1;
    },
    async refreshToken() {
      localStorage.removeItem('access_token');
      this.accessToken = null;
    },

  },
  async mounted() {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap';
    document.head.appendChild(link);

    const params = new URLSearchParams(window.location.hash.substr(1));
    const accessToken = params.get('access_token');
    if (accessToken) {
      this.accessToken = accessToken;
      const currentUrl = window.location.href;
      const tokenIndex = currentUrl.indexOf("#access_token=");
      if (tokenIndex !== -1) {
        const newUrl = currentUrl.substring(0, tokenIndex);
        window.location.replace(newUrl);
      }
    }
    if (this.accessToken) {
      localStorage.setItem("access_token", this.accessToken);
      this.loading = true;
      this.totalItems = await this.getPlaylistLength(this.accessToken);
      // this.artists = await this.getPlaylistArtists(this.accessToken);
      this.loading = false;
    }
  },
  created() {
    document.title = "SuperScrobbler";
  }
};
</script>

<style>
body {
  font-family: Montserrat, sans-serif;
  background-color: #000;
  color: #ccc;
  padding: 20px;
}

.progress-bar {
  font-size: 25px;
  margin: 5px 5px 20px;
}

.progress-bar .label {
  font-size: 15px;
}

.progress-bar .bar {
  height: 40px;
  line-height: 40px;
  padding: 10px;
  margin: 10px 10px 10px 0;

}

.stats {
  font-size: 25px;
  margin: 5px;
  padding: 5px;
  background: #111;
}

.results {
  font-size: 15px;
  margin: 5px;
  padding: 5px;
  background: #111;
}

.results th {
  text-align: left;
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 18px;
}

h1 {
  padding: 5px;
}

label {
  font-size: 20px;
  padding: 5px;
}

input {
  font-size: 20px;
  padding: 5px;
  margin: 5px 5px 5px 5px;
}

button {
  display: inline-block;
  width: auto;
  margin-left: 4px;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #ffffff;
  background-color: #4CAF50;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button {
  background-color: #3e8e41;
}

.intro {
  max-width: 800px;
  font-size: 20px;
  padding: 5px;
  color: #ccc;
}

a:link, a:visited {
  color: yellow;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>

